<template>
  <div>
    <div class="rosette-modal-content">
      <div class="circular-progress">
        <div class="progress-bar-modal">
          <img
            :src="`${selectedRosette.rosetteImageUrl}`"
            style="position: absolute; height: 70px; width: 70px;"
          />
        </div>
      </div>
      <VueText style="font-weight: 600; margin: 30px 0; font-size: 20px; text-align: center;">{{
        selectedRosette.rosetteName
      }}</VueText>
      <div class="meter">
        <span :style="{ width: `${calculateProgressValue(selectedRosette)}%` }" />
      </div>
      <VueText
        style="color: #23303D;text-align: center; font-size: 16px; font-weight: 400; margin-top:25px"
        >{{ selectedRosette.rosetteCount }} / {{ selectedRosette.rosetteReferenceCount }}</VueText
      >
      <div
        style="border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 10px; margin:15px 0; line-height: 20px"
      >
        <div class="rosette-description" v-html="selectedRosette.rosetteDescription"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';

export default {
  name: 'RosettePopupContent',
  components: { VueText },
  props: ['selectedRosette'],
  methods: {
    calculateProgressValue(f) {
      return (100 / f.rosetteReferenceCount) * f.rosetteCount;
    },
  },
};
</script>

<style scoped lang="scss">
.progress-bar {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  background-color: #ededed;
  align-items: center;
  justify-content: center;
}
.progress-bar::before {
  content: '';
  position: absolute;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  background-color: #fff;
}
.rosette-modal-content {
  width: 350px;
  height: 400px;
  margin-top: 20px;
}

.meter {
  box-sizing: content-box;
  height: 6px;
  position: relative;
  margin: 10px 0 10px 0;
  background: #d9d9d9;
  border-radius: 30px;
  box-shadow: inset 0 -1px 1px #d9d9d9;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: linear-gradient(315deg, #d20051 0%, #ffa300 100%);
  position: relative;
  overflow: hidden;
}
.progress-bar-modal {
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  background-color: #ededed;
  align-items: center;
  justify-content: center;
}

.progress-bar-modal::before {
  content: '';
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #fff;
}
.circular-progress {
  display: flex;
  border-radius: 8px;
  background: #fff;
  row-gap: 30px;
  flex-direction: column;
  align-items: center;
}

.rosette-description {
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #23303d;
}
</style>
